import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class MonthDayDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: string): string {
    if (displayFormat === 'MM/DD') {
      const month = date.getMonth() + 1; // Months are zero-based
      const day = date.getDate();
      return `${this._toTwoDigits(month)}/${this._toTwoDigits(day)}`;
    }
    return super.format(date, displayFormat);
  }

  private _toTwoDigits(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }
}
